import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { JobStatusLabel } from './JobStatusLabel';
import { UserApproval } from './UserApproval';
import { JobPageParams, JobStatus } from './utils';
import { humanDate } from 'utils/ui.utils';

interface JobStatusBarProps {
  readonly status: JobStatus;
  readonly submittedAt: string;
  readonly updatedAt: string | null;
  readonly hasWarnings: boolean;
}

const Label: React.FC<{ readonly text: string; readonly color: string }> = ({
  text,
  color
}) => (
  <Text
    style={{
      color,
      textTransform: 'uppercase',
      marginBottom: '10px'
    }}
    variation="footnote"
  >
    {text}
  </Text>
);

export const JobStatusBar: React.FC<JobStatusBarProps> = ({
  status,
  submittedAt,
  updatedAt,
  hasWarnings
}) => {
  const { tokens } = useContext(ThemeContext).theme;
  const { id: jobId } = useParams<JobPageParams>();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      style={{
        gap: '15px',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box>
        <Label color={tokens.colors.gray800} text="Status" />
        <JobStatusLabel hasWarnings={hasWarnings} status={status} />
      </Box>
      <Box>
        <Label color={tokens.colors.gray800} text="Agree?" />
        <UserApproval />
      </Box>
      <Box>
        <Label color={tokens.colors.gray800} text="Submitted" />
        <Text>{humanDate(submittedAt)}</Text>
      </Box>
      <Box>
        <Label color={tokens.colors.gray800} text="Updated" />
        <Text>{updatedAt ? humanDate(updatedAt) : '-'}</Text>
      </Box>
      <Box>
        <Label color={tokens.colors.gray800} text="Job Id" />
        <Text>{jobId}</Text>
      </Box>
    </Box>
  );
};
