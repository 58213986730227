import React, { FC } from 'react';
import ReactJson from 'react-json-view';
import { Job } from 'models/jobs/jobs';

export const Debug: FC<{ readonly job: Job }> = ({ job }) => {
  const debugResults =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    job.debugResults ||
    JSON.stringify({ results: 'No debug results to display' });

  let debugObj: object;
  try {
    debugObj = JSON.parse(debugResults) as object;
  } catch {
    debugObj = {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const templateId = (debugObj as any).results[0]?.result?.id?.docengine?.google
    ?.detect?.id as string | undefined;

  return (
    <div>
      {job.debugResults && templateId ? (
        <ul>
          <li>
            <a
              className="job-modal__debug__template-link"
              href={`${window.location.origin}/admin/templates/${templateId}`}
              rel="noreferrer"
              target="_blank"
            >
              Link to the template used for this ID
            </a>
          </li>
        </ul>
      ) : null}
      <ReactJson src={debugObj} />
    </div>
  );
};
