import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { ReactElement, useContext } from 'react';
import { Tooltip } from './Tooltip';

export interface DropdownItem {
  readonly available: boolean;
  readonly text: string;
  readonly statusIcon?: ReactElement;
  readonly tooltip?: string;
  readonly submittedData?: string;
}

interface DropdownItemsSectionProps {
  readonly dropdownItems: DropdownItem[];
  readonly table: boolean;
}

export const DropdownItemsSection: React.FC<DropdownItemsSectionProps> = ({
  dropdownItems,
  table
}) => {
  const { tokens } = useContext(ThemeContext).theme;

  const tCellStyles = {
    border: '1px solid #dddddd',
    padding: '8px',
    verticalAlign: 'center'
  };

  const thStyles = {
    textAlign: 'left' as const,
    backgroundColor: tokens.colors.purple,
    color: tokens.colors.white
  };

  return table ? (
    <Box marginY="spacing.xlarge">
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
          margin: '25px 0'
        }}
      >
        <tr>
          <th
            style={{
              ...tCellStyles,
              ...thStyles
            }}
          >
            Field
          </th>
          <th
            style={{
              ...tCellStyles,
              ...thStyles
            }}
          >
            Value
          </th>
          <th
            style={{
              ...tCellStyles,
              ...thStyles
            }}
          >
            Result
          </th>
        </tr>
        {dropdownItems.map((item) => (
          <tr key={item.text}>
            <td style={tCellStyles}>
              <Box alignItems="center" display="flex">
                <Text
                  style={{
                    marginRight: '10px',
                    ...(!item.available && {
                      color: tokens.colors.textInverseMuted
                    })
                  }}
                >
                  {item.text}
                </Text>
                <Tooltip>{item.tooltip}</Tooltip>
              </Box>
            </td>
            <td style={tCellStyles}>
              <Text
                style={{
                  ...(!item.available && {
                    color: tokens.colors.textInverseMuted
                  })
                }}
              >
                {item.submittedData}
              </Text>
            </td>
            <td style={tCellStyles}>
              <Box alignItems="center" display="flex">
                {item.statusIcon}
              </Box>
            </td>
          </tr>
        ))}
      </table>
    </Box>
  ) : (
    <Box
      display="grid"
      flex="1"
      marginY="spacing.xlarge"
      style={{
        gridTemplateColumns: 'auto auto 1fr',
        gap: '20px',
        alignItems: 'center'
      }}
    >
      {dropdownItems.map((item) => (
        <>
          {item.statusIcon}
          <Text
            style={{
              ...(!item.available && { color: tokens.colors.textInverseMuted })
            }}
          >
            {item.text}
          </Text>
          <Tooltip>{item.tooltip}</Tooltip>
        </>
      ))}
    </Box>
  );
};
