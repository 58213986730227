import { Box } from '@vouched-id/vault';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { JobStatus } from '../utils';
import { CrossCheckDropdown } from './CrossCheck/CrossCheckDropdown';
import { DLVDropdown } from './DLV/DLVDropdown';
import { DeleteDropdown } from './Delete/DeleteDropdown';
import { FilesDropdown } from './Files/FilesDropdown';
import { IdDocument } from './IdDocument/IdDocument';
import { FeatureNotEnabledDropdown } from './utils';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import { JobError, JobRequestParameters, JobResult } from 'models/jobs/jobs';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  DOWNLOADER_PERMISSIONS,
  isAuthorized
} from 'security/authorization';
import { useGlobalState } from 'store/reducers/reducer';

interface JobResultDropdownsProps {
  readonly jobErrors: JobError[];
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobStatus: JobStatus;
  readonly jobResult: JobResult | undefined;
  readonly crossCheckResult: CrossCheckResult | undefined;
}

const dropdownWrapperStyle = {
  margin: '0 auto 0 0',
  width: ['100%', '70%']
};

export const JobResultDropdowns: FC<JobResultDropdownsProps> = ({
  jobErrors,
  jobRequestParams,
  jobStatus,
  jobResult,
  crossCheckResult
}) => {
  const { user } = useGlobalState().state;
  const isAdminRoute = useLocation().pathname.includes('/admin');

  return (
    <>
      <Box style={dropdownWrapperStyle}>
        {!!jobResult && (
          <IdDocument
            jobErrors={jobErrors}
            jobRequestParams={jobRequestParams}
            jobResult={jobResult}
            jobStatus={jobStatus}
          />
        )}
      </Box>
      <Box style={dropdownWrapperStyle}>
        {crossCheckResult ? (
          <CrossCheckDropdown
            crossCheckResult={crossCheckResult}
            jobErrors={jobErrors}
            jobStatus={jobStatus}
          />
        ) : (
          <FeatureNotEnabledDropdown
            title="CrossCheck"
            url="https://docs.vouched.id/docs/crosscheck-2"
          />
        )}
      </Box>
      <Box style={dropdownWrapperStyle}>
        {jobResult?.dlv?.dlvEnabled ? (
          <DLVDropdown
            dlvJobResult={jobResult.dlv}
            dlvSubmittedData={{
              id: jobResult.id,
              state: jobResult.state,
              lastName: jobResult.lastName,
              birthDate: jobResult.birthDate,
              expirationDate: jobResult.expireDate
            }}
            jobStatus={jobStatus}
          />
        ) : (
          <FeatureNotEnabledDropdown
            title="DLV"
            url="https://docs.vouched.id/docs/dlv-1"
          />
        )}
      </Box>
      {!isAdminRoute && isAuthorized(user!, DOWNLOADER_PERMISSIONS) && (
        <Box style={dropdownWrapperStyle}>
          <FilesDropdown jobStatus={jobStatus} />
        </Box>
      )}
      {!isAdminRoute && isAuthorized(user!, ACCOUNT_ADMIN_PERMISSIONS) && (
        <Box style={dropdownWrapperStyle}>
          <DeleteDropdown />
        </Box>
      )}
    </>
  );
};
