import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { useContext } from 'react';
import { FontSizes, FontWeights } from '../../utils';
import { readableBoolean, readableString } from '../utils';
import {
  CrossCheckAddressResult,
  CrossCheckEkataIpResult,
  CrossCheckEmailResult,
  CrossCheckPhoneResult
} from 'models/jobs/crosscheck';
import { cleanArray } from 'utils/jobs';

const DetailsBox: React.FC = ({ children }) => (
  <Box
    display="grid"
    marginBottom="spacing.default"
    style={{
      gridTemplateColumns: 'auto 1fr',
      rowGap: '20px',
      columnGap: '25px',
      justifyItems: 'start',
      alignItems: 'start'
    }}
  >
    {children}
  </Box>
);

const DetailsTitle: React.FC = ({ children }) => {
  const { tokens } = useContext(ThemeContext).theme;
  const fontStyles = {
    fontWeight: tokens.text.weight.bold,
    fontSize: FontSizes.Medium
  };

  return (
    <Text
      style={{
        color: tokens.colors.textSecondary,
        marginBottom: '15px',
        ...fontStyles
      }}
    >
      {children}
    </Text>
  );
};

const DetailsValue: React.FC = ({ children }) => (
  <Text
    style={{
      fontWeight: FontWeights.SemiBold
    }}
  >
    {children}
  </Text>
);

interface CrossCheckDetailsProps {
  readonly address: CrossCheckAddressResult | null;
  readonly phone: CrossCheckPhoneResult | null;
  readonly email: CrossCheckEmailResult | null;
  readonly ip: CrossCheckEkataIpResult | null;
}

export const CrossCheckDetails: React.FC<CrossCheckDetailsProps> = ({
  address,
  phone,
  email,
  ip
}) => {
  return (
    <Box display="flex" flexWrap="wrap" style={{ gap: '50px' }}>
      <Box flex="1">
        <DetailsTitle>Address</DetailsTitle>
        <DetailsBox>
          <Text>Valid</Text>
          <DetailsValue>{readableBoolean(address?.isValid)}</DetailsValue>
          <Text>Match</Text>
          <DetailsValue>{readableBoolean(address?.isMatch)}</DetailsValue>
          <Text>Name</Text>
          <DetailsValue>{readableString(address?.name)}</DetailsValue>
          <Text>Type</Text>
          <DetailsValue>{readableString(address?.type)}</DetailsValue>
          <Text>Forwarder</Text>
          <DetailsValue>{readableBoolean(address?.isForwarder)}</DetailsValue>
          <Text>Commercial</Text>
          <DetailsValue>{readableBoolean(address?.isCommercial)}</DetailsValue>
          <Text>Errors</Text>
          <Text>{cleanArray(address?.errors, 'error').join(', ')}</Text>
          <Text>Warnings</Text>
          <Text>{cleanArray(address?.warnings, 'warnings').join(', ')}</Text>
        </DetailsBox>
      </Box>
      <Box flex="1">
        <DetailsTitle>Phone</DetailsTitle>
        <DetailsBox>
          <Text>Valid</Text>
          <DetailsValue>{readableBoolean(phone?.isValid)}</DetailsValue>
          <Text>Match</Text>
          <DetailsValue>{readableBoolean(phone?.isMatch)}</DetailsValue>
          <Text>Name</Text>
          <DetailsValue>{readableString(phone?.name)}</DetailsValue>
          <Text>Type</Text>
          <DetailsValue>{readableString(phone?.type)}</DetailsValue>
          <Text>Carrier</Text>
          <DetailsValue>{readableString(phone?.carrier)}</DetailsValue>
          <Text>Prepaid</Text>
          <DetailsValue>{readableBoolean(phone?.isPrepaid)}</DetailsValue>
          <Text>Disposable</Text>
          <DetailsValue>{readableBoolean(phone?.isDisposable)}</DetailsValue>
          <Text>Commercial</Text>
          <DetailsValue>{readableBoolean(phone?.isCommercial)}</DetailsValue>
          <Text>Errors</Text>
          <Text>{cleanArray(phone?.errors, 'error').join(', ')}</Text>
          <Text>Warnings</Text>
          <Text>{cleanArray(phone?.warnings, 'warnings').join(', ')}</Text>
        </DetailsBox>
      </Box>
      <Box flex="1">
        <DetailsTitle>Email</DetailsTitle>
        <DetailsBox>
          <Text>Valid</Text>
          <DetailsValue>{readableBoolean(email?.isValid)}</DetailsValue>
          <Text>Match</Text>
          <DetailsValue>{readableBoolean(email?.isMatch)}</DetailsValue>
          <Text>Name</Text>
          <DetailsValue>{readableString(email?.name)}</DetailsValue>
          <Text>Days First Seen</Text>
          <DetailsValue>{email?.daysFirstSeen ?? 'N/A'}</DetailsValue>
          <Text>Auto Generated</Text>
          <DetailsValue>{readableBoolean(email?.isAutoGenerated)}</DetailsValue>
          <Text>Disposable</Text>
          <DetailsValue>{readableBoolean(email?.isDisposable)}</DetailsValue>
          <Text>Errors</Text>
          <Text>{cleanArray(email?.errors, 'error').join(', ')}</Text>
          <Text>Warnings</Text>
          <Text>{cleanArray(email?.warnings, 'warnings').join(', ')}</Text>
        </DetailsBox>
      </Box>
      <Box flex="1">
        <DetailsTitle>IP</DetailsTitle>
        <DetailsBox>
          <Text>Valid</Text>
          <DetailsValue>{readableBoolean(ip?.isValid)}</DetailsValue>
          <Text>Distance</Text>
          <DetailsValue>{ip?.distance ?? 'N/A'}</DetailsValue>
        </DetailsBox>
      </Box>
    </Box>
  );
};
