import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useGlobalState } from 'store/reducers/reducer';
import { changeAccount, loadAccounts } from 'store/sagas/sagas';
import { useFlags } from 'utils/hooks';
export const UserAccount: React.FC = () => {
  const { state, asyncDispatch } = useGlobalState();

  const { subaccountFeature } = useFlags();

  useEffect(() => {
    if (!state.accounts && state.user) {
      asyncDispatch(loadAccounts(subaccountFeature)).catch(console.error);
    }
  }, [state.account && state.accounts]);

  return (
    <form autoComplete="off" className="g-form" noValidate>
      <span className="title">Change Account</span>
      {state.accounts && state.account && (
        <div>
          <Autocomplete
            className="g-full-width"
            debug
            defaultValue={state.account.name}
            onChange={(_e, value) => {
              if (state.accounts) {
                const account = state.accounts.filter((a) => a.name === value);
                if (account.length > 0) {
                  asyncDispatch(changeAccount(`${account[0]!.id}`)).catch(
                    console.error
                  );
                }
              }
            }}
            options={state.accounts.map((a) => a.name)}
            renderInput={(params) => (
              <TextField
                // style={{ width: 'auto' }}
                className="g-full-width"
                {...params}
                margin="normal"
              />
            )}
          />
        </div>
      )}
    </form>
  );
};
