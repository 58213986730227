import { Checkbox, Grid, Menu, MenuItem, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { List } from 'components/reusable/list';
import { ReusableSelect as Select } from 'components/reusable/select';
import { User } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import {
  AccountGroupUser,
  changeAccountPermissions,
  changeAccountPermissions_v2,
  getAccountGroupUsers,
  getAccountUsers,
  linkAccountGroupUser,
  linkUserInvite,
  loadUserInvites,
  postUserInvite,
  removeUserInvite,
  resendUserInvite
} from 'store/sagas/sagas';
import { validateEmail } from 'utils/email';
import './company-users.css';
import { useFlags } from 'utils/hooks';
import { humanDate } from 'utils/ui.utils';
import { useIsMobile } from 'utils/window';

export const CompanyUsers: React.FC = () => {
  type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const { state, asyncDispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [users, setUsers] = useState<User[] | null>(null);
  const [invites, setInvites] = useState<User[] | null>(null);

  const [anchor, setAnchor] = useState<{
    el: HTMLElement | null;
    id: number | null;
  }>({ el: null, id: null });

  const [resendIds, setResendIds] = useState<number[]>([]);
  const [copyIds, setCopyIds] = useState<number[]>([]);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const firstNameRef = useRef<HTMLInputElement | null>(null);

  const { subaccountFeature } = useFlags();

  const [accountGroupsUsers, setAccountGroupsUsers] = useState<
    AccountGroupUser[][] | null
  >(null);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isAccountAdmin, setIsAccountAdmin] = useState(false);
  const [showDisabled, setShowDisabled] = useState('Hide');
  const [accountGroup, setAccountGroup] = useState(0);
  const [is2FA, setIs2FA] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);
  const [isDownloader, setIsDownloader] = useState(false);
  const [invalidData, setInvalidData] = useState(false);

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setAnchor({ el: null, id: null });
    }, 0);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: number) => {
      setAnchor({ el: event.currentTarget, id });
    },
    []
  );

  const removeInvite = (id: number): void => {
    if (!invites) {
      return;
    }

    setInvites(invites.filter((ent) => ent.id !== id));
  };

  const updateInvite = (invite: User): void => {
    if (!invites) {
      return;
    }

    const newInvites: User[] = [...invites];

    const idx = invites.findIndex((j) => j.id === invite.id);
    if (idx >= 0) {
      newInvites[idx] = invite;
      setInvites(newInvites);
    }
  };

  useEffect(() => {
    if (!state.account?.name) {
      return;
    }

    if (!users) {
      setIsLoading(true);
      asyncDispatch(getAccountUsers({ isV2: !!subaccountFeature }))
        .then((res) => {
          const filteredUsers =
            showDisabled === 'Hide'
              ? res.users.filter((user) => !user.isDisabled)
              : res.users;

          setUsers(filteredUsers);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    if (!invites) {
      setIsInviteLoading(true);
      asyncDispatch(loadUserInvites())
        .then((res) => {
          setInvites(res.users);
          setIsInviteLoading(false);
        })
        .catch(() => {
          setIsInviteLoading(false);
        });
    }
  }, [state.account, showDisabled]);

  const handleChangeFilters = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    if (value === 'Show') {
      setShowDisabled('Show');
      asyncDispatch(getAccountUsers({ isV2: !!subaccountFeature }))
        .then((res) => {
          setUsers(res.users); // Set users to the original value of res.users
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setShowDisabled('Hide');
      if (!users) {
        return;
      }

      const filteredUsers = users.filter((user) => !user.isDisabled);
      setUsers(filteredUsers);
    }
  };

  useEffect(() => {
    if (!state.accountGroups) {
      return;
    }

    Promise.all(
      state.accountGroups.map(async ({ id: accountGroupId }) =>
        asyncDispatch(getAccountGroupUsers({ accountGroupId }))
      )
    ).then((newAccountGroupsUsers) => {
      setAccountGroupsUsers(newAccountGroupsUsers.map((n) => n.users));
    }, console.error);
  }, [state.accountGroups]);

  const user = {
    ...state.user
  } as User;

  const clearForm = () => {
    try {
      if (lastNameRef.current) {
        lastNameRef.current.value = '';
      }

      if (firstNameRef.current) {
        firstNameRef.current.value = '';
      }

      if (emailRef.current) {
        emailRef.current.value = '';
      }

      setEmail('');
      setLastName('');
      setFirstName('');
      setIsReviewer(false);
      setIsDownloader(false);
      setIsAccountAdmin(false);
      setIs2FA(false);
      setAccountGroup(0);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserAccountGroup = (
    userId: number,
    accountGroupId: number,
    remove: boolean
  ): void => {
    if (!accountGroupsUsers) return;
    let newAccountGroupsUsers = [...accountGroupsUsers];
    const found = newAccountGroupsUsers.find((a) =>
      a.find((i) => `${i.userId}` === `${userId}`)
    );

    if (!remove) {
      if (found) {
        // update it if found
        const agu = found.find((i) => `${i.userId}` === `${userId}`);
        if (agu) {
          agu.accountGroupId = accountGroupId;
        }
      } else {
        // add it if not found.
        newAccountGroupsUsers = [
          ...newAccountGroupsUsers,
          [
            {
              userId,
              accountGroupId,
              // todo: Make optional
              id: -1,
              sid: ''
            }
          ]
        ];
      }
    } else if (found) {
      // remove it
      const agu = found.findIndex(
        (i) =>
          `${i.userId}` === `${userId}` &&
          `${i.accountGroupId}` === `${accountGroupId}`
      );

      if (agu >= 0) {
        found.splice(agu, 1);
      }
    }

    setAccountGroupsUsers(newAccountGroupsUsers);
  };

  const toUserAccountGroup = (userId: number): number | null | undefined => {
    if (!accountGroupsUsers) return null;
    const found = accountGroupsUsers.find((a) =>
      a.find((i) => `${i.userId}` === `${userId}`)
    );

    const o = found
      ? found.find((i) => `${i.userId}` === `${userId}`)?.accountGroupId
      : null;

    return o;
  };

  const handleEmail = useCallback((e: InputEvent) => {
    setEmail(e.target.value.trim());
    setInvalidData(false);
  }, []);

  const handleLastName = useCallback((e: InputEvent) => {
    setLastName(e.target.value.trim());
    setInvalidData(false);
  }, []);

  const handleFirstName = useCallback((e: InputEvent) => {
    setFirstName(e.target.value.trim());
    setInvalidData(false);
  }, []);

  const handleAccountGroup = useCallback(
    (e: ChangeEvent<{ value: number | string; name: string }>) => {
      setAccountGroup(e.target.value as number);
      setInvalidData(false);
    },
    []
  );

  const changePermissions = (
    currentUser: User,
    {
      isAccountAdmin: newIsAccountAdmin,
      isReviewer: newIsReviewer,
      isDownloader: newIsDownloader,
      isDisabled,
      is2FA: newIs2FA
    }: {
      isAccountAdmin?: boolean;
      isDisabled?: boolean;
      isDownloader?: boolean;
      isReviewer?: boolean;
      is2FA?: boolean;
    }
  ) => {
    if (currentUser.id) {
      const permissions = {
        isAccountAdmin: newIsAccountAdmin ?? currentUser.isAccountAdmin,
        isDisabled: isDisabled ?? currentUser.isDisabled,
        isReviewer: newIsReviewer ?? currentUser.isReviewer,
        isDownloader: newIsDownloader ?? currentUser.isDownloader,
        is2FA: newIs2FA ?? currentUser.is2FA
      };

      asyncDispatch(
        subaccountFeature
          ? changeAccountPermissions_v2({ id: currentUser.id, permissions })
          : changeAccountPermissions({ id: currentUser.id, permissions })
      )
        .then((res) => {
          if (users?.length) {
            const idx = users.findIndex((u) => u.id === res.user.id);

            users[idx] = res.user;

            setUsers([...users]);
          }
        })
        .catch(console.error);
    }
  };

  const onLinkUserAccountGroup = async ({
    e,
    previous,
    userId
  }: {
    e: ChangeEvent<{ value: number; name: string }>;
    userId: number;
    previous: number | null | undefined;
  }) => {
    const accountGroupId = e.target.value;
    if (!accountGroupId) {
      return;
    }

    if (previous) {
      // remove out the previous
      await asyncDispatch(
        linkAccountGroupUser({
          accountGroupId: previous,
          userId,
          remove: true
        })
      );
    }

    if (e.target.value && e.target.value !== previous) {
      // add the new value
      await asyncDispatch(
        linkAccountGroupUser({
          accountGroupId: Number(e.target.value),
          userId,
          remove: false
        })
      );
    }

    const remove = !!(previous && !e.target.value);
    updateUserAccountGroup(userId, accountGroupId, remove);
  };

  const onInviteUser = () => {
    if (!validateEmail(email)) {
      setInvalidData(true);
      return;
    }

    setIsInviteLoading(true);
    asyncDispatch(
      postUserInvite({
        email,
        isReviewer: !!isReviewer,
        accountGroupId: accountGroup || null,
        isDownloader: !!isDownloader,
        isAccountAdmin: !!isAccountAdmin,
        is2FA: !!is2FA,
        lastName,
        firstName,
        v2: subaccountFeature
      })
    )
      .then(({ user: newUser }) => {
        setInvites([newUser, ...(invites ?? [])]);
        clearForm();
        setIsInviteLoading(false);
        if (accountGroup) {
          updateUserAccountGroup(newUser.id, accountGroup, false);
        }
      })
      .catch(() => {
        setIsInviteLoading(false);
      });
  };

  const isMobile = useIsMobile();

  return (
    <>
      <h1 className="g-page-name">Users</h1>
      <div className="filters-container-container">
        <div className="filters-container">
          <Grid container>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  className="g-full-width u-margin-bottom-small"
                  error={invalidData}
                  inputRef={emailRef}
                  label="Email"
                  onChange={handleEmail}
                  required
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={3}>
                <TextField
                  className="g-full-width u-margin-bottom-small"
                  error={invalidData}
                  inputRef={firstNameRef}
                  label="First name"
                  onChange={handleFirstName}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="g-full-width u-margin-bottom-small"
                  error={invalidData}
                  inputRef={lastNameRef}
                  label="Last name"
                  onChange={handleLastName}
                  required
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={2}>
                <div className="form-checkbox">Review</div>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  checked={isReviewer}
                  className="form-checkbox"
                  onChange={() => {
                    setIsReviewer(!isReviewer);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={2}>
                <div className="form-checkbox">Files</div>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  checked={isDownloader}
                  className="form-checkbox"
                  onChange={() => {
                    setIsDownloader(!isDownloader);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={2}>
                <div className="form-checkbox">Admin</div>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  checked={isAccountAdmin}
                  className="form-checkbox"
                  disabled={!!accountGroup}
                  onChange={() => {
                    setIsAccountAdmin(!isAccountAdmin);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={2}>
                <div className="form-checkbox">2FA</div>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  checked={is2FA}
                  className="form-checkbox"
                  onChange={() => {
                    setIs2FA(!is2FA);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={2}>
                <div className="form-checkbox">Group</div>
              </Grid>
              <Grid item xs={3}>
                {isAccountAdmin ? null : (
                  <Select
                    className="select-group"
                    items={(state.accountGroups ?? []).map((a) => ({
                      id: a.id,
                      name: a.name
                    }))}
                    name="pageSize"
                    onChange={handleAccountGroup}
                    value={accountGroup}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={3}>
                <ButtonWithLoader
                  className="u-margin-top-large"
                  disabled={!email || !firstName || !lastName}
                  isLoading={isLoading}
                  name="Invite User"
                  onClick={onInviteUser}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {user.isAccountAdmin && (
        <div className="filters-container-container">
          <div className="filters__container">
            <div
              className="filters"
              onClick={() => {
                setIsOpenFilters(!isOpenFilters);
              }}
            >
              <FilterListIcon
                style={{
                  color: isOpenFilters ? '#4122af' : 'grey'
                }}
              />
              <span>Filter</span>
            </div>
          </div>
        </div>
      )}
      {isOpenFilters && (
        <div className="filters-container">
          <Grid className="u-margin-top-medium" container>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={isMobile ? 12 : 3}>
                <Select
                  className={`g-full-width ${
                    isMobile ? 'u-margin-bottom-large' : ''
                  }`}
                  isClearable={false}
                  itemTestId="menu-disabled"
                  items={[
                    { id: 'Show', name: 'Show' },
                    { id: 'Hide', name: 'Hide' }
                  ]}
                  label="Disabled"
                  name="disabled"
                  onChange={handleChangeFilters}
                  testId="select-disabled"
                  value={showDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {!!invites?.length && (
        <List
          columns={[
            {
              name: 'Name',
              xs: 2,
              customRenderValue: ({
                firstName: displayFirstName,
                lastName: displayLastName
              }) => {
                return (
                  <>
                    <div className="text-ellipsis">{displayFirstName}</div>
                    <div className="text-ellipsis">{displayLastName}</div>
                  </>
                );
              }
            },
            {
              name: 'Email',
              xs: 2,
              id: 'email'
            },

            {
              name: 'Review',
              xs: 1,
              customRenderValue: (item) => {
                return (
                  <Checkbox
                    checked={!!item.isReviewer}
                    disabled
                    onChange={() => {
                      changePermissions(item, {
                        isReviewer: !item.isReviewer
                      });
                    }}
                  />
                );
              }
            },
            {
              name: 'Files',
              xs: 1,
              customRenderValue: (item) => {
                return (
                  <Checkbox
                    checked={!!item.isDownloader}
                    disabled
                    onChange={() => {
                      changePermissions(item, {
                        isDownloader: !item.isDownloader
                      });
                    }}
                  />
                );
              }
            },
            {
              name: 'Admin',
              xs: 1,
              customRenderValue: (item) => {
                return (
                  <Checkbox
                    checked={!!item.isAccountAdmin}
                    disabled
                    onChange={() => {
                      changePermissions(item, {
                        isAccountAdmin: !item.isAccountAdmin
                      });
                    }}
                  />
                );
              }
            },
            {
              name: '2FA',
              xs: 1,
              customRenderValue: (item) => {
                return (
                  <Checkbox
                    checked={!!item.is2FA}
                    disabled
                    onChange={() => {
                      changePermissions(item, {
                        is2FA: !item.is2FA
                      });
                    }}
                  />
                );
              }
            },
            {
              name: 'Days Left',
              xs: 1,
              customRenderValue: (item) => {
                const expire = new Date(item.inviteExpiredAt!);
                const now = new Date();
                const diff = Math.ceil(
                  (expire.getTime() - now.getTime()) / 1000 / 60 / 60 / 24
                );

                return diff > 0 ? `${diff}` : 'Expired';
              }
            },
            {
              xs: 1,
              name: '',
              isClickable: false,
              customRenderValue: ({ id }) => {
                return (
                  <div
                    className="pointer"
                    onClick={(e) => {
                      handleClick(e, id);
                    }}
                  >
                    <i className="candidates__menu_icon fas fa-ellipsis-v" />
                    <Menu
                      anchorEl={anchor.el}
                      id={String(id)}
                      keepMounted
                      onClose={handleClose}
                      open={anchor.id === id}
                    >
                      <MenuItem
                        onClick={() => {
                          setResendIds([...resendIds, id]);
                          asyncDispatch(resendUserInvite(id))
                            .then(({ user: newUser }) => {
                              updateInvite(newUser);
                              setTimeout(() => {
                                handleClose();
                              }, 500);
                            })
                            .catch(console.error);
                        }}
                      >
                        {resendIds.includes(id) ? 'Resent' : 'Resend'}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCopyIds([...copyIds, id]);
                          asyncDispatch(linkUserInvite(id))
                            .then((data) => {
                              navigator.clipboard
                                .writeText(data.url)
                                .catch(console.error);

                              setTimeout(() => {
                                handleClose();
                              }, 500);
                            })
                            .catch(console.error);
                        }}
                      >
                        {copyIds.includes(id) ? 'Invite copied' : 'Copy invite'}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          asyncDispatch(removeUserInvite(id))
                            .then(() => {
                              removeInvite(id);
                              setTimeout(() => {
                                handleClose();
                              }, 500);
                            })
                            .catch(console.error);
                        }}
                      >
                        Remove
                      </MenuItem>
                    </Menu>
                  </div>
                );
              }
            }
          ]}
          items={invites}
          label="invites"
          listSource="users"
          loading={isInviteLoading}
          total={invites.length}
        />
      )}
      <List
        columns={[
          {
            name: 'Name',
            xs: 1,
            customRenderValue: ({
              firstName: displayFirstName,
              lastName: displayLastName
            }) => {
              return (
                <>
                  <div className="text-ellipsis">{displayFirstName}</div>
                  <div className="text-ellipsis">{displayLastName}</div>
                </>
              );
            }
          },
          {
            name: 'Email',
            xs: 2,
            id: 'email'
          },
          {
            name: 'Last Login',
            xs: 2,
            customRenderValue: ({ loginAt }) => {
              return loginAt ? (
                <div className="text-ellipsis">{humanDate(loginAt)}</div>
              ) : null;
            }
          },
          {
            name: 'Review',
            xs: 1,
            customRenderValue: (item) => {
              return (
                <Checkbox
                  checked={!!item.isReviewer}
                  onChange={() => {
                    changePermissions(item, {
                      isReviewer: !item.isReviewer
                    });
                  }}
                />
              );
            }
          },
          {
            name: 'Files',
            xs: 1,
            customRenderValue: (item) => {
              return (
                <Checkbox
                  checked={!!item.isDownloader}
                  onChange={() => {
                    changePermissions(item, {
                      isDownloader: !item.isDownloader
                    });
                  }}
                />
              );
            }
          },
          {
            name: 'Admin',
            xs: 1,
            customRenderValue: (item) => {
              return (
                <Checkbox
                  checked={!!item.isAccountAdmin}
                  disabled={!!toUserAccountGroup(item.id)}
                  onChange={() => {
                    changePermissions(item, {
                      isAccountAdmin: !item.isAccountAdmin
                    });
                  }}
                />
              );
            }
          },
          {
            name: '2FA',
            xs: 1,
            customRenderValue: (item) => {
              return (
                <Checkbox
                  checked={!!item.is2FA}
                  onChange={() => {
                    changePermissions(item, {
                      is2FA: !item.is2FA
                    });
                  }}
                />
              );
            }
          },
          {
            name: 'Disabled',
            xs: 1,
            customRenderValue: (item) => {
              return (
                <Checkbox
                  checked={!!item.isDisabled}
                  onChange={() => {
                    changePermissions(item, {
                      isDisabled: !item.isDisabled
                    });
                  }}
                />
              );
            }
          },
          {
            name: 'Group',
            xs: 2,
            customRenderValue: ({
              isAccountAdmin: displayIsAccountAdmin,
              id
            }) => {
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              const value = toUserAccountGroup(id) || 0;
              const items = (state.accountGroups ?? []).map((a) => ({
                id: a.id,
                name: a.name
              }));

              return displayIsAccountAdmin ? null : (
                <Select<number>
                  className="select-group"
                  items={items}
                  name="pageSize"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onChange={async (e) =>
                    onLinkUserAccountGroup({
                      e,
                      previous: toUserAccountGroup(id),
                      userId: id
                    })
                  }
                  value={value}
                />
              );
            }
          }
        ]}
        items={users}
        label="users"
        listSource="users"
        loading={isLoading}
        total={users ? users.length : 0}
      />
    </>
  );
};
