import { CrossCheckResult } from './crosscheck';
import { JobType } from 'components/account/jobs/job_type';

export interface JobError {
  type: string;
  suggestion?: string;
  message?: string;
  warnings?: string[];
  warning?: string;
}

interface BarcodeData {
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthDate?: string;
  expireDate?: string;
  issueDate?: string;
}

interface ResultConfidences {
  id?: number | null;
  selfie?: number | null;
  faceMatch?: number | null;
  nameMatch?: number | null;
  selfieSunglasses?: number;
  selfieEyeglasses?: number;
  idMatch?: number | null;
  birthDateMatch?: number | null;
  idQuality?: number;
  idGlareQuality?: number;
  idExpired?: number | null;
  idCrosscheckDarkweb?: number | null;
  idCrosscheckActivity?: number | null;
  idCrosscheckIdentity?: number | null;
  idAml?: number | null;
  idFraud?: number | null;
  barcode?: number | null;
  barcodeMatch?: number | null;
  liveness?: number | null;
  livenessSession?: number | null;
}

export interface AAmvaResult {
  hasErrors: boolean;
  status?: string | null;
  confidenceScore?: number | null;
  statusMessage?: string;
  updatedAt?: string;
  completedAt: boolean;
  enabled?: boolean | null;
  createdAt?: string;
}

type DlvIdentityResponse = VerifyDlvResponse | null;

export interface VerifyDlvResponse {
  stateSupported: boolean;
  checkPerformedAt: string;
  wasCheckPerformed: boolean;
  status: string;
  source: string;
  fieldMatchesAvailable: string[];
  fieldMatchesFailed: string[];
  fieldMatchesSucceeded: string[];
  fieldMatchesUnavailable: string[];
}

export enum DlvStatusMessage {
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  ERROR = 'Error',
  NOT_APPLICABLE = 'Not Applicable'
}

export enum DlvPipelineError {
  UnsupportedLicenseType = 'unsupported-license-type',
  UnsupportedCountry = 'unsupported-country',
  MissingState = 'missing-state',
  MissingLicenseNumber = 'missing-license-number'
}

export interface DlvJobResult {
  error?: DlvPipelineError;
  dlvCheckPerformed: boolean;
  dlvStatusMessage: DlvStatusMessage;
  dlvEnabled: boolean;
  result: DlvIdentityResponse;
}

export interface JobResult {
  // Unsure if this exists
  aml?: {
    data?: object;
  };
  dlv?: DlvJobResult;
  type?: string;
  state?: string;
  country?: string;
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthDate?: string;
  expireDate?: string;
  issueDate?: string;
  class?: string;
  endorsements?: string;
  motorcycle?: string;
  address?: string;
  unverifiedIdAddress?: string;
  gender?: {
    gender: string;
  };
  barcodeData?: BarcodeData;
  clientOutput?: {
    theme?: string;
    client?: string;
    includeBarcode?: boolean;
    scanditEnabled?: boolean;
  };
  ipAddress?: {
    userType: string;
    isAnonymous: boolean;
    city?: string;
    state?: string;
    country?: string;
    isp?: string;
    organization?: string;
    confidence: number;
    warnings?: string[];
    location?: {
      latitude: number;
      longitude: number;
    };
  } | null;
  idAddress?: {
    streetNumber?: string;
    street?: string;
    city?: string;
    country?: string;
    state?: string;
  };
  aamva?: AAmvaResult;
  featuresEnabled?: {
    dlvEnabled: boolean;
  };
  crosscheck?: CrossCheckResult;
  confidences?: ResultConfidences;
  successWithSuggestion?: boolean;
  success?: boolean;
  warnings?: boolean;
}

interface Dimension {
  width: number;
  height: number;
}

interface PhotoData {
  idPhotoUrl?: string;
  idPhotoDimensions?: Dimension;
  userPhotoUrl?: string;
  userPhotoDimensions?: Dimension;
  backIdPhotoUrl?: string;
  backIdPhotoDimensions?: Dimension;
  userDistancePhotoUrl?: string;
  userDistanceDimensions?: Dimension;
  idDistancePhotoUrl?: string;
  idDistanceDimensions?: Dimension;
  idPhotoDetectUrl?: string;
  idPhotoDetectDimensions?: Dimension;
  userPhotoDetectUrl?: string;
  userPhotoDetectDimensions?: Dimension;
  sourceUrl?: string;
  sourceDimensions?: Dimension;
  destUrl?: string;
  destDimensions?: Dimension;
}

export interface JobRequestParameters extends PhotoData {
  /**
   * Original job for reverification?
   */
  id?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  address?: {
    streetAddress: string;
    city: string;
    state: string;
    unit: string;
    postalCode: string;
    country: string;
  };
  email?: string;
  phone?: string;
  scannedBarcode?: string;
  dob?: string;
  userConfirmation?: {
    confirmed?: boolean | null;
  };
}

export interface Insights {
  category: string;
  message: string;
  type: string;
  fields: string[] | [];
  property: string;
}

export interface Job {
  signals?: Insights[];
  isTest?: boolean;
  accountId: string;
  accountGroupSid: string;
  id: string;
  status: string;
  submitted: string;
  completed: boolean;
  accountReviewed: boolean | null;
  stage?: string;
  review?: JobUpdateReview;
  reviewPass: boolean | null;
  reviewedAt: string | null;
  updatedAt: string | null;
  request: {
    type: JobType;
    properties: { name: string; value: string }[] | null;
    parameters: JobRequestParameters | null;
    requestInfo?: {
      referer?: string;
      useragent?: string;
      ipaddress?: string;
    };
  };
  secondaryPhotos?: { url: string; name: string }[];
  errors: JobError[];
  result?: JobResult;
  debugResults?: string;
  reviewSuccess?: boolean | null;
  country?: string;
  surveyPoll?: number;
  surveyMessage?: string;
}

export interface JobUpdateReview {
  idValid?: boolean | null;
  selfieValid?: boolean | null;
  faceMatch?: boolean | null;
  nameMatch?: boolean | null;
  birthDateMatch?: boolean | null;
}

export interface Jobs {
  total: number;
  page: number;
  pageSize: number;
  totalPages: number;
  items: Job[];
}

export enum JobImageType {
  Selfie,
  IDFront,
  IDBack
}
