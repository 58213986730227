// Only allowed importer
// eslint-disable-next-line no-restricted-imports
import { useFlags as useFlagsRaw } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsAdminRoute = (): boolean => {
  const { pathname } = useLocation();
  return pathname.includes('admin');
};

interface Flags {
  candidatesFeature?: boolean;
  dashboardFeature?: boolean;
  jobPageRedesign?: boolean;
  subaccountFeature?: boolean;
}

export const useFlags = (): Flags => {
  return useFlagsRaw<Flags>();
};

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
