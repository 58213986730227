import { Box } from '@vouched-id/vault';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { JobPageParams, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { useGlobalState } from 'store/reducers/reducer';
import { downloadJob } from 'store/sagas/sagas';
import { base64ToArrayBuffer, saveByteArray } from 'utils/ui.utils';

interface FilesDropdownProps {
  readonly jobStatus: JobStatus;
}

export const FilesDropdown: FC<FilesDropdownProps> = ({ jobStatus }) => {
  const { asyncDispatch } = useGlobalState();
  const { id: jobId } = useParams<JobPageParams>();

  const handleDownload = (id: string, confValue: boolean) => {
    asyncDispatch(downloadJob(id, confValue, false))
      .then((res) => {
        const fileName = `IDV_${id}_Internal_Use.pdf`;
        const sampleBytes = base64ToArrayBuffer(res.pdf);
        saveByteArray([sampleBytes], fileName);
      })
      .catch(console.error);
  };

  return (
    <Dropdown title="Files">
      {jobStatus !== 'active' && (
        <>
          <Box marginBottom="spacing.default">
            <a
              onClick={() => {
                handleDownload(jobId, true);
              }}
            >
              <i aria-hidden="true" className="pointer fas fa-download" />
              Report
            </a>
          </Box>
          <Box marginBottom="spacing.default">
            <a
              onClick={() => {
                handleDownload(jobId, false);
              }}
            >
              <i aria-hidden="true" className="pointer fas fa-download" />
              NoConfReport
            </a>
          </Box>
        </>
      )}
    </Dropdown>
  );
};
