import {
  Box,
  Button,
  ButtonLink,
  Heading,
  Input,
  Stack,
  Text,
  ThemeContext
} from '@vouched-id/vault';
import React, { FC, FormEvent, useCallback, useContext, useState } from 'react';
import { VouchedLogo } from '../reusable/vouched-logo';
import { TwoFactorData } from 'models/auth-data/two-factor-data.model';
import { set2FAData, setAuthData, setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { login, login_v2 } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { useFlags } from 'utils/hooks';
import { humanDate } from 'utils/ui.utils';
import { FieldState, formatInput, ResponseType } from 'utils/validation';

export const Signin: FC = () => {
  const { dispatch, asyncDispatch } = useGlobalState();
  const { subaccountFeature } = useFlags();
  const { tokens } = useContext(ThemeContext).theme;
  const desktopLogoColor = tokens.colors.backgroundPrimary;

  const [userProfile, setUserProfile] = useState({ email: '', password: '' });
  const [isInvalid, setIsInvalid] = useState<ResponseType>({
    email: FieldState.NOT_SET,
    password: FieldState.NOT_SET
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'email') {
      const { formattedValue } = formatInput(e);
      value = formattedValue;
    }

    setUserProfile((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'email') {
      if (value === '') {
        setIsInvalid({ ...isInvalid, [name]: FieldState.NOT_SET });
      } else {
        const { isValid } = formatInput(e);
        setIsInvalid({
          ...isInvalid,
          [name]: isValid ? FieldState.VALID : FieldState.INVALID
        });
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const { dashboardFeature } = useFlags();

  const submit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    const { email } = userProfile;
    const { password } = userProfile;

    if (email && password) {
      setIsLoading(true);
      asyncDispatch(
        subaccountFeature
          ? login_v2({ email, password, recaptchaToken: 'n/a' })
          : login({ email, password, recaptchaToken: 'n/a' })
      )
        .then((data) => {
          if ('otpToken' in data) {
            dispatch(set2FAData(data as TwoFactorData));
            history.push('/verify-2fa');
          } else if (data.user.loginAt) {
            dispatch(
              setMessages({
                severity: 'info',
                value: `Last login at ${humanDate(data.user.loginAt)!}`
              })
            );

            dispatch(setAuthData(data));
            if (dashboardFeature) {
              history.push('/account/dashboard');
            } else {
              history.push('/account/jobs');
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsInvalid({
            email: FieldState.INVALID,
            password: FieldState.INVALID
          });

          console.error('Signin Error', error);
        });
    }
  };

  // eventually update vault theme
  const midPurple = '#453FC1';
  const brightGreen = '#9FEA99';

  return (
    <Box
      display={['block', 'flex']}
      height="100vh"
      padding={['spacing.large', 'spacing.none']}
      style={{ boxSizing: 'border-box', backgroundColor: midPurple }}
      tag="main"
    >
      <Box
        alignItems="center"
        backgroundColor="colors.backgroundPrimary"
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        paddingX="spacing.large"
        paddingY="spacing.xlarge"
        position="relative"
        style={{
          borderRadius: ['spacing.default', '0'],
          boxSizing: 'border-box',
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      >
        <Box
          display={['block', 'none']}
          marginBottom="spacing.large"
          textAlign="center"
        >
          <VouchedLogo fill={midPurple} maxWidth="none" width="100%" />
          <Box marginBottom="spacing.xlarge" marginTop="spacing.default">
            <Text
              style={{
                color: 'colors.textMuted',
                fontWeight: '600',
                fontSize: ['text.size.default', null, 'text.size.large']
              }}
              tag="span"
            >
              It's Who You Know
            </Text>
          </Box>
          <Heading
            style={{
              fontWeight: 'bold'
            }}
            tag="h1"
            variation="h3"
          >
            Sign In
          </Heading>
        </Box>
        <Box display={['none', 'block']} marginBottom="spacing.xlarge2">
          <Heading style={{ fontWeight: 'bold' }} tag="h1" variation="h3">
            Sign In to Your Account
          </Heading>
        </Box>
        <Box
          maxWidth="52rem"
          noValidate
          onSubmit={submit}
          tag="form"
          width="100%"
        >
          <Stack spacing="spacing.large">
            <Box>
              <Input
                autoComplete="email"
                inputMode="email"
                isInvalid={isInvalid['email'] === FieldState.INVALID}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                validationMessage="Invalid email address."
              />
            </Box>
            <Box>
              <Input
                inputMode="text"
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                validationMessage="Invalid password."
              />
            </Box>
            <Box
              marginTop="spacing.small"
              paddingRight="spacing.none"
              style={{
                a: { height: 'initial' }
              }}
              textAlign="right"
            >
              <ButtonLink
                data-cy="forgot-password"
                href="/forgot-password"
                variation="text"
              >
                Forgot your Password?
              </ButtonLink>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              marginTop="spacing.default"
              paddingTop="spacing.default"
              width="100%"
            >
              <Button
                data-cy="submit"
                style={{ backgroundColor: midPurple }}
                title={isLoading ? 'loading' : ''}
              >
                Sign In
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display={['none', 'flex']}
        flex="1"
        flexDirection="column"
        justifyContent="center"
        paddingX="spacing.default"
      >
        <VouchedLogo fill={desktopLogoColor} maxWidth="50rem" width="80%" />
        <Box marginTop="spacing.large">
          <Text
            style={{
              color: brightGreen,
              fontWeight: '600',
              fontSize: ['text.size.default', null, 'text.size.large']
            }}
            tag="span"
          >
            It's Who You Know
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
