import { Grid, TextField } from '@material-ui/core';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { List } from 'components/reusable/list';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { checkKey, deleteKey, existKey, updateKey } from 'store/sagas/sagas';
import './source-key.css';

export const SourceKey: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();

  const [isLoading, setIsLoading] = useState(false);
  const [publicKey, setPublicKey] = useState<string | null>(null);
  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [signatureKey, setSignatureKey] = useState<string | null>(null);
  const [groupKeys, setGroupKeys] = useState<Record<string, string>>({});
  const [sandboxPublicKey, setSandboxPublicKey] = useState<string | null>(null);
  const [sandboxPrivateKey, setSandboxPrivateKey] = useState<string | null>(
    null
  );

  const nameRef = useRef<HTMLInputElement | null>(null);
  const [currentKey, setCurrentKey] = useState('');
  const [keyExist, setKeyExist] = useState(false);

  const handleKey = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCurrentKey(e.target.value);
  }, []);

  useEffect(() => {
    if (state.user?.accountId) {
      setIsLoading(false);

      asyncDispatch(existKey('signature'))
        .then((data) => {
          setKeyExist(data.key);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const onCopyKey =
    (
      type:
        | 'private'
        | 'public'
        | 'sandboxPrivate'
        | 'sandboxPublic'
        | 'signature',
      id?: number | string | null
    ) =>
    () => {
      let copyData: string | null | undefined;
      if (id) {
        copyData = groupKeys[`${type}.${id}`] ?? '';
      } else {
        switch (type) {
          case 'public': {
            copyData = publicKey;

            break;
          }

          case 'private': {
            copyData = privateKey;

            break;
          }

          case 'signature': {
            copyData = signatureKey;

            break;
          }

          case 'sandboxPublic': {
            copyData = sandboxPublicKey;

            break;
          }

          case 'sandboxPrivate': {
            copyData = sandboxPrivateKey;

            break;
          }
          // No default
        }
      }

      if (!copyData) {
        return;
      }

      navigator.clipboard.writeText(copyData).catch((error: Error) => {
        console.error('failed to write to clipboard', error);
      });

      dispatch(setMessages({ value: 'Copied!', severity: 'success' }));
    };

  const onUpdatePublicKey = (accountGroupId: number | null) => {
    setIsLoading(true);
    asyncDispatch(updateKey('public', accountGroupId))
      .then(({ key }) => {
        if (accountGroupId === null) {
          setPublicKey(key);
        } else {
          setGroupKeys((prev) => ({
            ...prev,
            [`public.${accountGroupId}`]: key
          }));
        }
      }, console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUpdatePrivateKey = (accountGroupId: number | null) => {
    setIsLoading(true);
    asyncDispatch(updateKey('private', accountGroupId))
      .then(({ key }) => {
        if (accountGroupId === null) {
          setPrivateKey(key);
        } else {
          setGroupKeys((prev) => ({
            ...prev,
            [`private.${accountGroupId}`]: key
          }));
        }
      }, console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUpdateSandboxKey = (accountGroupId: number | null) => {
    setIsLoading(true);
    asyncDispatch(updateKey('public', accountGroupId, 'sandbox'))
      .then(({ key }) => {
        if (accountGroupId === null) {
          setSandboxPublicKey(key);
        } else {
          setGroupKeys((prev) => ({
            ...prev,
            [`sandboxPublic.${accountGroupId}`]: key
          }));
        }
      }, console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUpdateSandboxPrivateKey = (accountGroupId: number | null) => {
    setIsLoading(true);
    asyncDispatch(updateKey('private', accountGroupId, 'sandbox'))
      .then(({ key }) => {
        if (accountGroupId === null) {
          setSandboxPrivateKey(key);
        } else {
          setGroupKeys((prev) => ({
            ...prev,
            [`sandboxPrivate.${accountGroupId}`]: key
          }));
        }
      }, console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUpdateSignatureKey = async (
    accountGroupId: number | null,
    action = 'create'
  ) => {
    setIsLoading(true);
    try {
      if (action === 'create') {
        await asyncDispatch(updateKey('signature', accountGroupId)).then(
          ({ key }) => {
            if (accountGroupId === null) {
              setSignatureKey(key);
            } else {
              setGroupKeys((prev) => ({
                ...prev,
                [`signature.${accountGroupId}`]: key
              }));
            }
          }
        );

        return;
      }

      await asyncDispatch(deleteKey('signature'));
    } catch (error) {
      // supress
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateKey = (
    type: 'private' | 'public' | 'signature',
    accountGroupId: number | null,
    action = 'create'
  ) => {
    // eslint-disable-next-line no-alert
    if (!confirm(`Are you sure you want to ${action} this ${type} key?`)) {
      return;
    }

    switch (type) {
      case 'public':
        onUpdatePublicKey(accountGroupId);
        return;
      case 'signature':
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onUpdateSignatureKey(accountGroupId, action);
        return;
      default:
        onUpdatePrivateKey(accountGroupId);
    }
  };

  const onCheckKey = () => {
    asyncDispatch(checkKey(currentKey, null))
      .then(({ status }) => {
        dispatch(
          setMessages(
            status
              ? {
                  value: `Your key is valid`,
                  severity: 'success'
                }
              : {
                  value: `Your key is invalid`,
                  severity: 'error'
                }
          )
        );
      })
      .catch(console.error);
  };

  return (
    <div className="source-main">
      <h1 className="g-page-name">API Keys</h1>
      <form autoComplete="off" className="g-form" noValidate>
        <span className="title">Public Keys</span>
        <p>
          Once recreated, the original keys will be gone. Please be certain.
        </p>
        <div
          className="g-form"
          style={{
            width: 'calc(98% - 60px)',
            height: '10px',
            padding: '10px 30px 10px 30px'
          }}
        >
          {publicKey && (
            <div className="pointer" onClick={onCopyKey('public')}>
              <i className="fas fa-copy text-weak u-margin-right-large" />
              <span>{publicKey}</span>
            </div>
          )}
        </div>

        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={false}
          isLoading={isLoading}
          name="Recreate Public Key"
          onClick={() => {
            onUpdateKey('public', null, 'create');
          }}
        />
      </form>

      <form autoComplete="off" className="g-form" noValidate>
        <span className="title">Private Keys</span>
        <p>
          Once recreated, the original keys will be gone. Please be certain.
        </p>
        <div
          className="g-form"
          style={{
            width: 'calc(98% - 60px)',
            height: '10px',
            padding: '10px 30px 10px 30px'
          }}
        >
          {privateKey && (
            <div className="pointer" onClick={onCopyKey('private')}>
              <i className="fas fa-copy text-weak u-margin-right-large" />
              <span>{privateKey}</span>
            </div>
          )}
        </div>
        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={false}
          isLoading={isLoading}
          name="Recreate Private Key"
          onClick={() => {
            onUpdateKey('private', null, 'create');
          }}
        />
      </form>
      <form autoComplete="off" className="g-form" noValidate>
        <span className="title">Sandbox Public Keys</span>
        <p>
          Once recreated, the original keys will be gone. Please be certain.
        </p>
        <div
          className="g-form"
          style={{
            width: 'calc(98% - 60px)',
            height: '10px',
            padding: '10px 30px 10px 30px'
          }}
        >
          {sandboxPublicKey && (
            <div className="pointer" onClick={onCopyKey('sandboxPublic')}>
              <i className="fas fa-copy text-weak u-margin-right-large" />
              <span>{sandboxPublicKey}</span>
            </div>
          )}
        </div>

        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={false}
          isLoading={isLoading}
          name="Recreate Sandbox Public Key"
          onClick={() => {
            onUpdateSandboxKey(null);
          }}
        />
      </form>
      <form autoComplete="off" className="g-form" noValidate>
        <span className="title">Sandbox Private Keys</span>
        <p>
          Once recreated, the original keys will be gone. Please be certain.
        </p>
        <div
          className="g-form"
          style={{
            width: 'calc(98% - 60px)',
            height: '10px',
            padding: '10px 30px 10px 30px'
          }}
        >
          {sandboxPrivateKey && (
            <div className="pointer" onClick={onCopyKey('sandboxPrivate')}>
              <i className="fas fa-copy text-weak u-margin-right-large" />
              <span>{sandboxPrivateKey}</span>
            </div>
          )}
        </div>

        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={false}
          isLoading={isLoading}
          name="Recreate Sandbox Private Key"
          onClick={() => {
            onUpdateSandboxPrivateKey(null);
          }}
        />
      </form>
      <form autoComplete="off" className="g-form" noValidate>
        <span className="title">Signature Keys</span>
        <p>
          <a
            href="https://docs.vouched.id/#section/Overview/Security-and-Privacy"
            rel="noreferrer"
            target="_blank"
          >
            This is a replacement for the private key
          </a>{' '}
          .
        </p>
        <div
          className="g-form"
          style={{
            width: 'calc(98% - 60px)',
            height: '10px',
            padding: '10px 30px 10px 30px'
          }}
        >
          {signatureKey && (
            <div className="pointer" onClick={onCopyKey('signature')}>
              <i className="fas fa-copy text-weak u-margin-right-large" />
              <span>{signatureKey}</span>
            </div>
          )}
        </div>
        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={false}
          isLoading={isLoading}
          name="Recreate Signature Key"
          onClick={() => {
            onUpdateKey('signature', null, 'create');
          }}
        />
        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={!keyExist}
          isLoading={isLoading}
          name="Delete Signature Key"
          onClick={() => {
            onUpdateKey('signature', null, 'delete');
          }}
        />
      </form>

      <form autoComplete="off" className="g-form" noValidate>
        <span className="title">Check Key Status</span>
        <p>Paste key in box to check if key is active.</p>
        <div style={{ display: 'flex' }}>
          <Grid container>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  className="g-full-width u-margin-bottom-small"
                  inputRef={nameRef}
                  label="Key"
                  onChange={handleKey}
                  required
                  style={{ minWidth: 200 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>

        <ButtonWithLoader
          className="u-margin-bottom-small"
          color="secondary"
          disabled={false}
          isLoading={isLoading}
          name="Check Key Status"
          onClick={onCheckKey}
        />
      </form>

      {!!state.accountGroups?.length && (
        <List
          columns={[
            {
              name: 'Id',
              xs: 2,
              id: 'sid'
            },
            {
              name: 'Name',
              xs: 2,
              id: 'name'
            },
            {
              xs: 3,
              name: '',
              isClickable: false,
              customRenderValue: ({ id }) => {
                return (
                  <>
                    <div
                      className="g-form"
                      style={{
                        width: '300px',
                        height: '10px',
                        padding: '10px 30px 10px 30px'
                      }}
                    >
                      {groupKeys[`public.${id}`] && (
                        <div
                          className="pointer"
                          onClick={onCopyKey('public', id)}
                        >
                          <i className="fas fa-copy text-weak u-margin-right-large" />
                          <span>{groupKeys[`public.${id}`]}</span>
                        </div>
                      )}
                    </div>
                    <ButtonWithLoader
                      className="u-margin-bottom-small"
                      color="secondary"
                      disabled={false}
                      isLoading={isLoading}
                      name="Recreate Public Key"
                      onClick={() => {
                        onUpdateKey('public', id);
                      }}
                    />
                  </>
                );
              }
            },
            {
              xs: 3,
              name: '',
              isClickable: false,
              customRenderValue: ({ id }) => {
                return (
                  <>
                    <div
                      className="g-form"
                      style={{
                        width: '300px',
                        height: '10px',
                        padding: '10px 30px 10px 30px'
                      }}
                    >
                      {groupKeys[`private.${id}`] && (
                        <div
                          className="pointer"
                          onClick={onCopyKey('private', id)}
                        >
                          <i className="fas fa-copy text-weak u-margin-right-large" />
                          <span>{groupKeys[`private.${id}`]}</span>
                        </div>
                      )}
                    </div>
                    <ButtonWithLoader
                      className="u-margin-bottom-small"
                      color="secondary"
                      disabled={false}
                      isLoading={isLoading}
                      name="Recreate Private Key"
                      onClick={() => {
                        onUpdateKey('private', id);
                      }}
                    />
                  </>
                );
              }
            }
          ]}
          items={state.accountGroups}
          label="groups"
          listSource="key"
          loading={isLoading}
          total={state.accountGroups.length}
        />
      )}
    </div>
  );
};
