import { Button, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { AccountCreateForm } from './account-create-form';
import { ColumnProp, List } from 'components/reusable/list';
import { ReusableSelect as Select } from 'components/reusable/select';
import { CompanyAccount } from 'models/company-account/company-account.model';
import { useGlobalState } from 'store/reducers/reducer';
import { getAccounts } from 'store/sagas/sagas';
import { history } from 'utils/history';
import './account-manager.css';
import { useDebounce, useFlags } from 'utils/hooks';
import {
  MapData,
  pushFiltersSearchPath,
  QueryData,
  rawParseQuery,
  transformQueryTypes
} from 'utils/url';

interface Props {
  readonly basePath: string;
}

const goToAccount = (accountId: number) => {
  history.push(`/admin/accounts/${accountId}`);
};

const filterOptions = {
  selfServe: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  isDisabled: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  pageSize: [
    { id: 10, name: '10' },
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 250, name: '250' },
    { id: 500, name: '500' }
  ]
};

const initialData = {
  name: '',
  selfServe: '',
  page: 1,
  pageSize: 50,
  isDisabled: '',
  firstName: '',
  lastName: '',
  id: '',
  sid: '',
  email: ''
};

const mapData: MapData = {
  name: 'string',
  selfServe: 'boolean',
  page: 'number',
  pageSize: 'number',
  isDisabled: 'boolean',
  firstName: 'string',
  lastName: 'string',
  id: 'string',
  sid: 'string',
  email: 'string'
};

interface Filter {
  name: string;
  selfServe: string;
  page: number;
  pageSize: number;
  isDisabled: string;
  firstName: string;
  lastName: string;
  id: string;
  sid: string;
  email: string;
}

export const AccountManager: React.FC<Props> = ({ basePath }) => {
  const { state } = useGlobalState();
  const { subaccountFeature } = useFlags();

  const [localState, setLocalState] = useState({
    accounts: [] as CompanyAccount[],
    loading: false,
    totalAccounts: 0
  });

  const qsData = transformQueryTypes(mapData, rawParseQuery());
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const [filter, setFilter] = useState<Filter>({
    ...initialData,
    ...qsData
  });

  const debouncedFilter = useDebounce(filter, 250);

  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    updateFilter({
      ...filter,
      page
    });
  };

  useEffect(() => {
    const { isInitial } = pushFiltersSearchPath({
      filter: debouncedFilter as unknown as QueryData,
      initialData,
      mapData,
      path: basePath
    });

    if (!isOpenFilters && !isInitial) {
      setIsOpenFilters(true);
    }

    setLocalState({
      ...localState,
      loading: true
    });

    getAccounts(state.accessToken, debouncedFilter, !!subaccountFeature)
      .then(({ accounts, total }) => {
        setLocalState({
          ...localState,
          accounts,
          totalAccounts: total,
          loading: false
        });
      })
      .catch(() => {
        setLocalState({
          ...localState,
          loading: false
        });
      });
  }, [debouncedFilter]);

  const updateFilter = (newFilter: Partial<Filter>) => {
    setFilter({ ...initialData, ...newFilter });
  };

  const handleChangeFilters = (
    e: ChangeEvent<{ value: number | string; name: string }>
  ) => {
    updateFilter({
      ...filter,
      page: 1,
      [e.target.name]: e.target.value
    });
  };

  const columns: ColumnProp<CompanyAccount>[] = [
    {
      xs: 1,
      name: 'Name',
      id: 'name'
    },
    {
      xs: 1,
      name: 'ID',
      id: 'id'
    },
    {
      xs: 1,
      name: 'SID',
      id: 'sid'
    },
    {
      xs: 2,
      name: 'Email',
      id: 'email'
    },
    {
      xs: 1,
      name: 'Self-Serve',
      customRenderValue: (item) => {
        return (!!item.subscriptionTier).toString();
      }
    },
    {
      xs: 2,
      name: 'Subscription ID',
      id: 'subscriptionId'
    },
    {
      xs: 2,
      name: 'Customer ID',
      id: 'stripeCustomerId'
    },
    {
      xs: 2,
      name: 'Disabled',
      id: 'isDisabled'
    }
  ];

  const { accounts, loading, totalAccounts } = localState;

  return (
    <div className="account-manager-container">
      <AccountCreateForm />
      <div className="filters__container">
        <div
          className="filters"
          onClick={() => {
            setIsOpenFilters(!isOpenFilters);
          }}
        >
          <FilterListIcon
            style={{
              color: isOpenFilters ? '#4122af' : 'grey'
            }}
          />
          <span>Filter</span>
        </div>

        {isOpenFilters && (
          <div className="jobs__filters-container-container">
            <div className="jobs__filters-container u-padding-top-medium u-margin-bottom-large">
              <div className="u-margin-bottom-large">
                <Select
                  className="g-input-width-16"
                  items={filterOptions.pageSize}
                  label="Page Size"
                  name="pageSize"
                  onChange={handleChangeFilters}
                  value={filter.pageSize}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Id"
                  name="id"
                  onChange={handleChangeFilters}
                  value={filter.id}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Sid"
                  name="sid"
                  onChange={handleChangeFilters}
                  value={filter.sid}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Email"
                  name="email"
                  onChange={handleChangeFilters}
                  value={filter.email}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Name"
                  name="name"
                  onChange={handleChangeFilters}
                  value={filter.name}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="First Name"
                  name="firstName"
                  onChange={handleChangeFilters}
                  value={filter.firstName}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Last Name"
                  name="lastName"
                  onChange={handleChangeFilters}
                  value={filter.lastName}
                />
                <Select
                  className="g-input-width-16"
                  items={filterOptions.selfServe}
                  label="Self Serve"
                  name="selfServe"
                  onChange={handleChangeFilters}
                  value={filter.selfServe}
                />
                <Select
                  className="g-input-width-16"
                  items={filterOptions.isDisabled}
                  label="Disabled"
                  name="isDisabled"
                  onChange={handleChangeFilters}
                  value={filter.isDisabled}
                />
              </div>
              <div className="u-margin-bottom-large">
                <Button
                  color="primary"
                  onClick={() => {
                    updateFilter(initialData);
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="account-grid">
        <List
          columns={columns}
          handlePageChange={handlePageChange}
          isClickable={() => true}
          items={accounts}
          label="Accounts"
          loading={loading}
          onClick={(item) => {
            goToAccount(item.id);
          }}
          page={filter.page}
          total={totalAccounts}
          totalPages={Math.ceil(totalAccounts / filter.pageSize)}
        />
      </div>
    </div>
  );
};
